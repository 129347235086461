import {FaLocationDot} from "react-icons/fa6";
import {FaPaypal} from "react-icons/fa";

const Location = () => {
    return (
        <section className="location" aria-label='Location'>
            <div className='locationContent'>
              <FaLocationDot class='locationIcon'/>
              <div>
                <p>We are located at:</p>
                <ul>
                  <li>34758 Pratt Rd.</li>
                  <li>Memphis, Michigan 48041</li>
                  <li>P.O. Box 41014</li>
                </ul>
                <a href='https://www.paypal.com/donate?hosted_button_id=WQF7VEQHUT3J6' className='btn btn--Blue donate-button'><FaPaypal className='paypalIcon' /> Donate Here</a>
                <small>Or send check to the address above.</small>
              </div>
            </div>

            <div className='locationMap'>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1033.4975472007402!2d-82.77132935323496!3d42.890307231502774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8825065db6e06a57%3A0x536877e86b913bc8!2sMemphis%20Lions%20Club%20%26%20Youth%20Center!5e0!3m2!1sen!2sus!4v1697861687516!5m2!1sen!2sus"
                height="200"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
        </section>
    )
}

export default Location;