import TheBar from '../../media/images/bar.jpg';

const TheHall = () => {
  return (
    <div className='theHall'>
      <img src={TheBar} alt="The Bar"/>
      <div className='theHallContent'>
        <h2>The Hall</h2>
        <p>
          The Memphis Youth Center and Lions Hall is available for rent. The hall is 40' x 80' and can seat up to 160 people. The hall is available for weddings, showers, graduation parties, and other events with a full kitchen (future) and bar available. A large parking lot and handicap accessible entrance are also on site. For more information or to reserve the hall, contact us today.
        </p>

        <h2>Important Links</h2>
        <div className="theHallContent buttons">
          <a href="https://e-clubhouse.org/sites/memphis_michigan/page-6.php" className="btn btn--Yellow">Hall Rental</a>
          <a href="https://e-clubhouse.org/sites/memphis_michigan/projects.php" className="btn btn--Yellow">Club Projects</a>
          <a href="https://e-clubhouse.org/sites/memphis_michigan/photos.php" className="btn btn--Yellow">Photo Gallery</a>
        </div>

        {/*<div className="buttons">*/}
        {/*  <a href="tel:586-855-9570" className="btn btn--Yellow"><FaPhone /> <span className='learnMore'> Learn More</span></a>*/}
        {/*  <a target='_blank' href="http://calendar.google.com/calendar/embed?src=memphismilionsclub%40gmail.com&ctz=America%2FDetroit" className="btn btn--Blue"><FaCalendar /> <span className='learnMore'> View Availability</span></a>*/}
        {/*  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">*/}
        {/*    <input type="hidden" name="cmd" value="_s-xclick"/>*/}
        {/*    <input type="hidden" name="hosted_button_id" value="F3NRN7223PUAE"/>*/}
        {/*    <button type='submit' className="btn btn--Yellow payPal"><FaPaypal /> <span className='learnMore'> Pay Deposit</span></button>*/}
        {/*  </form>*/}
        {/*</div>*/}


      </div>
    </div>
  )
}

export default TheHall;