import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';

function App() {
  return (
    <div className='pageContent'>
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
