import  Logo  from '../../media/images/logo-long.png';
import {FaPhone} from 'react-icons/fa';

const Header = () => {
    return (
        <header>
          <div className="upperHeader">
            <img className="logo" src={Logo} alt="Memphis MI Lions"/>
            <div className='right-nav-links'>
            <a className='btn btn--Yellow space-bottom' href="tel:586-855-9570"><FaPhone className='phoneIcon'/> <span className='hide-mobile'>(586) 855-9570</span></a>
              <a href="https://e-clubhouse.org/sites/memphis_michigan/index.php" className='e-clubhouse'><span className='hide-mobile'>View</span> E-Clubhouse <span className='hide-mobile'>Site</span></a>

            </div>
          </div>
          <div className="lowerHeader">
            <h1>Memphis, Michigan <span>Lions Club</span></h1>
            <p>District 11-D2</p>
          </div>
        </header>

    )
}

export default Header;