const Footer = () => {
  const year = new Date().getFullYear();

    return (
        <footer className="footer">
          <p>&copy; Copyright {year} - This site is the property of the Memphis Michigan Lions Club.</p>
        </footer>
    );
}

export default Footer;