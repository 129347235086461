import HeroBgMobile from '../../media/images/hero-bg-mobile.png';
const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h2 className='quote'>Lions meet the needs of local communities and the world every day because they share a core belief -</h2>
        <h2 className='tagline'>To Serve Their Community</h2>
        <img className='heroMobile' src={HeroBgMobile} alt="Memphis Lions Club"/>
      </div>
    </div>
  )
}

export default Hero;