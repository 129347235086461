import Leos from '../../media/images/leos.jpg'
import Disaster from '../../media/images/disaster.jpg';
import {FaHandshake} from "react-icons/fa";
import QRCode from '../../media/images/QRCode.png'

const YouthAndDisaster = () => {
  return (
    <div className='youthAndDisaster'>
      <div className="infoCol youth">
        <img src={Leos} alt="Leos"/>
        <h3>Lions Serve Youth</h3>
        <p>
          Our community projects often support local children and
          schools through scholarships, recreation and mentoring.
          Internationally, we offer many programs, including the Peace
          Poster Contest, Youth Camps and Exchange and Lions Quest.
        </p>
      </div>
      <div className="infoCol meeting">
        <FaHandshake class='meetingIcon'/>
        <div className='meetingContent'>
          <h3>Lions Meetings</h3>
          <p>The Lions meet every 2nd and 4th Tuesday of the month at 7PM.</p>
          <p>Donate Here:</p>
          <img src={QRCode} alt="donate here"/>
        </div>
      </div>
      <div className="infoCol disaster">
        <img src={Disaster} alt="Disaster"/>
        <h3>Lions Help During Disasters</h3>
        <p>
          Together, our Foundation and Lions are helping communities following
          natural disasters by providing for immediate needs such as food, water,
          clothing and medical supplies – and aiding in long-term reconstruction.
        </p>
      </div>
    </div>
  )
}

export default YouthAndDisaster;