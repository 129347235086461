import Hero from '../page-components/Hero';
import Location from '../page-components/Location';
import TheHall from '../page-components/TheHall';
import Community from '../page-components/Community';
import YouthAndDisaster from '../page-components/YouthAndDisaster';

const Home = () => {
  return (
    <>
      <Hero />
      <Location />
      <TheHall />
      <Community />
      <YouthAndDisaster />
    </>
  )
}

export default Home;