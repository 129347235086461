import CommunityImg from '../../media/images/community.png';

const Community = () => {
  return (
    <div className='community'>
      <div className="communityContent">
        <h2>A Dynamic History</h2>
        <p> Founded in 1917, we are best known for fighting blindness,
          but we also volunteer for many different kinds of community projects -
          including caring for the environment, feeding the hungry and aiding seniors
          and the disabled.</p>
        <p>
          By conducting vision screenings, equipping hospitals and clinics, distributing medicine and raising awareness of eye disease, Lions work toward their mission of providing vision for all. We have extended our commitment to sight conservation through countless local efforts and through our international SightFirst Program, which works to eradicate blindness.
        </p>
      </div>
      <img src={CommunityImg} alt=""/>
    </div>
  )
}

export default Community;